<template>
  <div class="navbar-header" >

    <span class="brand-logo">
      <b-img
        :src="appLogoImage"
        alt="logo"
      />
    </span>
  </div>      
</template>

<script>
import {  BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {

    BImg,
  },
  setup() {
    // App Name
    const {  appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },
}
</script>

<style>

</style>
